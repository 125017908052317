export const globalMixin = {
  data: () => {
    return {
      // global
      showHeaderMenu: false,
    }
  },
  created: function () {},
  methods: {
    helloMixin: function () {
      console.log('hello from mixin!')
    }
  }
}
